import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import App from "./App.vue";
// import SomePlugin from 'some-plugin';

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// router
import router from "./router";

// include script file
import "./lib/CustomScript";

// include all css files
import "./lib/CustomCss";

window.$ = require("jquery");
window.JQuery = require("jquery");

// Vue.use(SomePlugin); // Pastikan ini dilakukan sebelum membuat instance Vue

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBtdO5k6CRntAMJCF-H5uZjTCoSGX95cdk", // Add your here your google map api key
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  components: { App },
}).$mount("#app");

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     // Cek apakah pengguna sudah login
//     if (!isLoggedIn()) {
//       // Jika belum login, arahkan ke halaman login atau halaman lainnya
//       next({ path: "/login" });
//     } else {
//       // Jika sudah login, izinkan akses ke rute yang memerlukan autentikasi
//       next();
//     }
//   } else {
//     // Rute yang tidak memerlukan autentikasi, izinkan akses
//     next();
//   }
// });

// function isLoggedIn() {
//   // Implementasi logika autentikasi sesuai kebutuhan Anda
//   // Misalnya, periksa apakah ada token atau informasi login di penyimpanan lokal
//   return localStorage.getItem("user") !== null;
// }
