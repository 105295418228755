<!-- App Main Structure -->
<template>
  <div id="wrapper" :class="{ 'header-top': isListingPages() }">
    <!--Header Frontend Panel -->
    <app-header class="app-header1"></app-header>

    <!-- Main Container -->
    <router-view></router-view>

    <!-- Footer Frontend Panel -->
    <app-footer v-if="isFooter" class="app-footer1"></app-footer>
  </div>
</template>

<script>
import Header from "Components/Header/Header.vue";
import Footer from "Components/Footer/Footer.vue";

export default {
  data() {
    return {
      isFooter: true,
      url: this.$route.path,
    };
  },
  components: {
    appHeader: Header,
    appFooter: Footer,
  },
  watch: {
    $route() {
      if (this.isFixedShow()) {
        this.isFooter = true;
      } else {
        this.isFooter = false;
      }
    },
  },
  methods: {
    isListingPages() {
      if (
        this.url == "/listing/half-map/grid" ||
        this.url == "/listing/half-map/list"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isFixedShow() {
      if (
        this.url == "/listing/half-map/grid" ||
        this.url == "/listing/half-map/list"
      ) {
        return false;
      } else {
        return true;
      }
    },
    onScrollEvent() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (scrollTop >= 200) {
        document.getElementById("wrapper").classList.add("header-fixed");
      } else {
        document.getElementById("wrapper").classList.remove("header-fixed");
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.onScrollEvent);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScrollEvent);
  },
};
</script>

<style>
.app-header1 {
  margin-bottom: 6.9%;
}
@media only screen and (max-width: 767px) {
  .app-header1 {
    margin-bottom: 20%;
  }
  .app-footer1 {
	margin-top: 10%;
  }
}

/* Gaya untuk tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .app-header1 {
    margin-bottom: 20%;
  }
  .app-footer1 {
	margin-top: 10%;
  }
}
</style>
