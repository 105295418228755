import Vue from 'vue';
import Router from 'vue-router';

// ... other imports ...

Vue.use(Router);

// ... your routes definition ...

// routes
import frontendRoutes from './frontend';
import adminendRoutes from './admin';

// session components
const SignUp = () => import('Views/session/SignUp');
const Login = () => import('Views/session/Login');
const ForgotPassword = () => import('Views/session/ForgotPassword');
const ComingSoon = () => import('Views/session/ComingSoon');

export default new Router({
  mode: 'history',
  routes: [
    frontendRoutes,
    adminendRoutes,
    {
      path: '/session/login',
      component: Login,
      meta: {
        title: 'Login',
      },
    },
  ],
});
