<template>
  <header class="header-global">
    <nav
      id="navbar-main"
      class="navbar navbar-main fixed-top navbar-expand-lg navbar-transparent navbar-light headroom"
    >
      <div class="container">
        <div id="logo">
          <router-link to="/dashboard">
            <h2 class="text-white">Papan Reklame</h2>
          </router-link>
        </div>
        <button
          @click="toggleNavbar"
          class="navbar-toggler"
          type="button"
          aria-controls="navbar_global"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div :class="{ 'show': showNavbar }" class="navbar-collapse collapse" id="navbar_global">
          <div class="navbar-collapse-header">
            <div class="row">
              <div class="col-10 collapse-brand">
                <div id="logo">
                  <router-link to="/dashboard">
                    <h2 class="text-white">Papan Reklame</h2>
                  </router-link>
                </div>
              </div>
              <div class="col collapse-close">
                <button @click="toggleNavbar" type="button" class="navbar-toggler" aria-label="Toggle navigation">
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
          <div class="navbar-nav navbar-nav-hover align-items-lg-center ml-auto">
            <ul class="m-0 p-0">
              <app-menu></app-menu>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import Menu from "Components/Menu/Menu.vue";

export default {
  data() {
    return {
      showNavbar: false,
    };
  },
  components: {
    appMenu: Menu,
  },
  methods: {
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
  },
};
</script>
