<!-- Header Structure -->
<template>
  <div id="footer" class="sticky-footer bg-primary pt-5">
    <!-- Main -->
    <div class="container">
      <div class="row d-lg-flex gap-5 justify-content-center">
        <div class="col-md-6 col-sm-6">
          <h2 class="mb-0 text-white pb-0">Papan Reklame</h2>
          <!-- <br><br> -->
          <p class="text-white">
            Billboards are promotional or advertising media which usually take
            the form of large boards placed in strategic locations such as
            roadsides, buildings or other public areas. The purpose of this
            billboard is to be able to see the history of vehicle traffic.
          </p>
          <!-- <div class="text-widget text-white">
						<span class="text-white">12345 Little Lonsdale St, Melbourne</span> <br>
						Phone: <span class="text-white">(123) 123-456 </span><br>
						E-Mail:<span class="text-white"> <a href="#" class="text-white">office@example.com</a> </span><br>
					</div>
					<div class="mt-4">
						<a target="_blank" href="https://twitter.com/" class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip" data-original-title="Follow us">
						<i class="fa fa-twitter"></i>
						</a>
						<a target="_blank" href="https://www.facebook.com/" class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip" data-original-title="Like us">
						<i class="fa fa-facebook-square"></i>
						</a>
						<a target="_blank" href="https://dribbble.com/" class="btn btn-neutral btn-icon-only  btn-lg btn-round" data-toggle="tooltip" data-original-title="Follow us">
						<i class="fa fa-dribbble"></i>
						</a>
						<a target="_blank" href="https://github.com/" class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip" data-original-title="Star on Github">
						<i class="fa fa-github"></i>
						</a>
					</div> -->
        </div>
        <div class="col-md-6 col-sm-6">
          <ul class="footer-links">
            <li>
              <router-link class="text-white" to="/session/login"
                >Login</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/dashboard"
                >Dashboard</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/map"
                >Map</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/list/titik/reklame"
                >List Titik Reklame</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- Copyright -->
      <div class="row">
        <div class="col-md-12">
          <div class="copyrights text-white">
            © 2023 Papan Reklame. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
