import FrontendPanel from 'Layout/frontendPanel/FrontendPanel'

// dashboard components
const DashboardTwo = () => import('Views/dashboard/DashboardTwo');
const HalfScreenMapList = () => import('Views/listing/HalfScreenMapList');
const HalfScreenMapGrid = () => import('Views/listing/HalfScreenMapGrid');
const ListingDetailTwo = () => import('Views/listing/ListingDetailTwo');
const ListingDetailTri = () => import('Views/listing/ListingDetailTri');
const FoodStreat = () => import('../views/category/restaurant/FoodStreat.vue');
const PizzaHut = () => import('../views/category/restaurant/PizzaHut.vue');
const Talaga = () => import('../views/category/restaurant/Talaga.vue');
const Susilo = () => import('../views/category/hotel/Susilo.vue');
const Zest = () => import('../views/category/hotel/Zest.vue');
const Aloft = () => import('../views/category/hotel/Aloft.vue');
const Bassura = () => import('../views/category/mall/Basura.vue');
const Ciplaz = () => import('../views/category/mall/Ciplaz.vue');
const Pondok = () => import('../views/category/mall/Pondok.vue');
const Map = () => import('../../src/views/pages/Map.vue');
const Detail = () => import('../../src/views/detail/Detail.vue');
const PopularCategory = () => import('../components/globalFrontendComponents/PopularCategories.vue');

export default {
   path: '/',
   component: FrontendPanel,
   redirect: '/session/login',
   children: [
      {
         path: '/popular-category',
         component: PopularCategory,
         meta: {
            title: 'Popular Category',
         }
      },
      {
         path: '/dashboard',
         component: DashboardTwo,
         meta: {
            title: 'Home 2',
            requiresAuth: true
         }
      },
      {
         path: '/map',
         component: Map,
         meta: {
            title: 'Map',
            requiresAuth: true
         }
      },
      {
         path: '/list/titik/reklame',
         component: HalfScreenMapList,
         meta: {
            title: 'List Titik Reklame',
            requiresAuth: true
         }
      },
      {
         path: '/listing/half-map/grid',
         component: HalfScreenMapGrid,
         meta: {
            title: 'Half Screen Map Grid',
            requiresAuth: true
         }
      },
      {
         path: '/grafik/history/jakarta-utara',
         component: ListingDetailTwo,
         meta: {
            title: 'History Keramaian Kendaraan',
            requiresAuth: true
         }
      },
      {
         path: '/grafik/history/jakarta-selatan',
         component: ListingDetailTri,
         meta: {
            title: 'History Keramaian Kendaraan',
            requiresAuth: true
         }
      },
      {
         path: '/food/street',
         component: FoodStreat,
         meta: {
            title: 'Preview Food Street',
            requiresAuth: true
         }
      },
      {
         path: '/pizza/hut',
         component: PizzaHut,
         meta: {
            title: 'Preview Pizza Hut',
            requiresAuth: true
         }
      },
      {
         path: '/talaga/sampireun',
         component: Talaga,
         meta: {
            title: 'Preview Talaga Sampireun',
            requiresAuth: true
         }
      },
      {
         path: '/susilo/radience',
         component: Susilo,
         meta: {
            title: 'Preview Susilo Radience 10',
            requiresAuth: true
         }
      },
      {
         path: '/zest/airport/jakarta',
         component: Zest,
         meta: {
            title: 'Preview Zest Airport Jakarta by Swiss-Belhotel International',
            requiresAuth: true
         }
      },
      {
         path: '/aloft/south/jakarta',
         component: Aloft,
         meta: {
            title: 'Preview Aloft South Jakarta',
            requiresAuth: true
         }
      },
      {
         path: '/mall/bassura',
         component: Bassura,
         meta: {
            title: 'Preview Mall@Basura',
            requiresAuth: true
         }
      },
      {
         path: '/ciplaz/clender',
         component: Ciplaz,
         meta: {
            title: 'Preview Aloft South JakartCiplaz Clender',
            requiresAuth: true
         }
      },
      {
         path: '/pondok/kelapa/town/squre',
         component: Pondok,
         meta: {
            title: 'Preview Pondok Kelapa Town Square',
            requiresAuth: true
         }
      },
      {
         path: '/detail/:id',
         component: Detail,
         meta: {
            title: 'Detail',
            requiresAuth: true
         }
      },
   ]
}
