export const menus = [
  {
    state: "/dashboard",
    name: "Dashboard",
    type: "link",
    // children: [{ state: "/dashboard", name: "Dashboard", type: "link" }],
  },
  {
    state: "/map",
    name: "Map",
    type: "link",
  },
  {
    state: "/list/titik/reklame",
    name: "List Titik Reklame",
    type: "link",
  },
//   {
//     state: "/admin",
//     name: "User Panel",
//     type: "sub",
//     children: [
//       { state: "/admin/dashboard", name: "Dashboard", type: "link" },
//       { state: "/admin/messages", name: "Messages", type: "link" },
//       { state: "/admin/bookings", name: "Bookings", type: "link" },
//       { state: "/admin/reviews", name: "Reviews", type: "link" },
//       { state: "/admin/bookmarks", name: "Bookmarks", type: "link" },
//       { state: "/admin/list", name: "My Listing", type: "link" },
//       { state: "/admin/add-list", name: "Add List", type: "link" },
//       { state: "/admin/profile", name: "Profile", type: "link" },
//     ],
//   },
//   {
//     state: "/pages",
//     name: "Pages",
//     type: "sub",
//     children: [
//       { state: "/pages/pricing", name: "Pricing", type: "link" },
//       { state: "/pages/invoice", name: "Invoice", type: "link" },
//       { state: "/pages/booking", name: "Booking", type: "link" },
//       { state: "/pages/add-listing", name: "Add Listing", type: "link" },
//       { state: "/pages/user-profile", name: "Profile", type: "link" },
//       {
//         state: "/blog",
//         name: "blog",
//         type: "sub",
//         children: [
//           { state: "/pages/blog/list", name: "List", type: "link" },
//           { state: "/pages/blog/detail", name: "Detail", type: "link" },
//         ],
//       },
//       { state: "/pages/about-us", name: "About", type: "link" },
//       { state: "/pages/contact-us", name: "Contact", type: "link" },
//     ],
//   },
//   {
//     state: "/session",
//     name: "Session",
//     type: "sub",
//     children: [
//       { state: "/session/login", name: "Login", type: "link" },
//       { state: "/session/sign-up", name: "Signup", type: "link" },
//       {
//         state: "/session/forgot-password",
//         name: "Forgot Password",
//         type: "link",
//       },
//       { state: "/session/coming-soon", name: "Coming Soon", type: "link" },
//     ],
//   },
];

// export default menus;
